@import '../Constants/Styles/colors.scss';

.ConstructorPage {
  overflow: auto;
  min-height: 0;
  height: 100vh;
  padding-bottom: 120px;
}

.ConstructorPage__wrapper {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 8px;
  }

  #opium-tabs-component-tab-derivative-active {
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
  }

  .ConstructorForm__wrapper {
    border: 1px solid #5D5F7C;
    padding: 1.5rem 1rem;
    border-radius: 10px;
    width: 450px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    @media (max-width: 1024px) {
      width: 400px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    .selectors-wrapper__divided {
      display: flex;
      justify-content: space-between;

      @media (max-width: 320px) {
        flex-direction: column;
      }

      .select-with-label {
        width: calc((100% - 2rem) / 2);

        @media (max-width: 320px) {
          width: 100%;
        }
      }

      .divided-block {
        width: 100%;
        display: flex;
        flex-direction: column;

        .labels-wrapper {
          display: flex;
          padding: 7px 0;
          align-items: center;
          font-size: 18px;

          .label-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-family: "Montserrat", sans-serif;
            cursor: pointer;

            position: static;
            width: 70px;
            height: 24px;
            left: 0px;
            top: 0px;
            color: #fff;

            background: rgba(93, 95, 124, 0.15);
            border-radius: 100px;

            &.label-wide {
              width: 120px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }

            &.color-scheme-LIGHT {
              color: #0C0C23;
            }

            .label-wrapper {
              display: flex;
              justify-content: center;

              &.label-right {
                margin-left: 5px;
              }

              img {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .selectors-wrapper__row {
      @media (max-width: 375px) {
        flex-direction: column;
        width: 100%;

        .select-with-label {
          width: 100%;
        }
      }
    }

    .ticker {
      margin-bottom: 2rem;

      &-title {
        color: #999BBC;
        margin-bottom: 1rem;
        font-size: 12px;

        @media (max-width: 425px) {
          font-size: 13px;
        }
      }

      &-text {
        font-size: 16px;

        @media (max-width: 360px) {
          font-size: 14px;
        }

        @media (max-width: 320px) {
          font-size: 13px;
        }

        &.color-scheme-DARK {
          color: #fff;
        }

        &.color-scheme-LIGHT {
          color: #0C0C23;
        }
      }
    }

    .derivative-hash {
      margin-bottom: 2rem;

      &-title {
        margin-bottom: 1rem;
        color: #999BBC;
        font-size: 12px;

        @media (max-width: 425px) {
          font-size: 13px;
        }
      }

      &-text {
        width: max-content;
        color: #fff;
        font-size: 14px;
        background-color: rgba(93, 95, 124, 0.15);
        padding: 4px 8px;
        border-radius: 100px;
        margin-bottom: 0 !important;

        @media (max-width: 360px) {
          font-size: 14px;
        }

        @media (max-width: 320px) {
          font-size: 13px;
        }

        &.color-scheme-DARK {
          color: #fff;
        }

        &.color-scheme-LIGHT {
          color: #0C0C23;
        }
      }

      &-content {
        display: flex;
        justify-content: space-between;

        img {
          cursor: pointer;
        }
      }
    }

    select {
      background: transparent;
      color: #fff;
      padding: 5px;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      border-radius: 0;
      outline: none;
      width: 100%;
    }

    option {
      background: #0C0C23;
      color: #fff;
    }

    input[type="number"] {
      background: transparent;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      color: #fff;
      padding: 5px;
      width: 100%;
    }
  }

  .ConstructorPage__right {
    padding: 0 0 0 3rem;

    @media (max-width: 1024px) {
      width: 450px;
    }

    @media (max-width: 768px) {
      margin-top: 3rem;
      padding: 0 3rem;
      width: 100%;
    }

    @media (max-width: 425px) {
      padding: 0 0.5rem;
    }

    .ConstructorData__wrapper {
      width: 100%;

      .constructor-data-prices {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 36px;
        margin-bottom: 36px;

        @media (max-width: 768px) {
          justify-content: space-between;
        }

        .constructor-data-prices-item {
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          height: 6rem;
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          @media (max-width: 1024px) {
            justify-content: space-between;
            padding: 0.5rem 1rem;
          }

          &-title {
            color: #999BBC;
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @media (max-width: 375px) {
              min-height: 1.8rem;
              display: flex;
              align-items: flex-start;
            }
          }

          &-text {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @media (max-width: 360px) {
              font-size: 20px;
              line-height: 22px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }

            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-left: 3rem;

            @media (max-width: 375px) {
              margin-left: 1rem;
            }
          }
        }
      }

      .constructor-data-charts {
        &-title {
          border-bottom: 1px solid white;
          margin-bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          color: white;

          &.color-scheme {
            &-DARK {
              color: $white0;
            }

            &-LIGHT {
              color: $darkblue1;
            }
          }

          img {
            margin-bottom: 4px;
            cursor: pointer;
          }
        }

        &-wrapper {
          width: 100%;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          left: 0;
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          .color-scheme-DARK {
            color: white;
          }

          .color-scheme-LIGHT {
            color: #0C0C23;
          }
        }

        &-no-data {
          height: 256px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;

        }
      }

      .ConstructorPage__right {

        &-block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .block-title {
            margin-bottom: 5px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;

            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;

            &.color-scheme-DARK {
              color: #fff;
            }

            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }
        }
      }
    }
  }

  .constructor-block-title-DARK {
    width: auto;
    font-size: 16px;
    color: white;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid white;
    // width: 30%;
  }

  .constructor-block-title-LIGHT {
    width: auto;
    font-size: 16px;
    color: #0C0C23;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid #0C0C23;
    // width: 30%;
  }

  .constructor-form-bottom {
    margin-bottom: 40px;
  }
}

.CustomChart {
  @media (max-width: 1024px) {
    width: 400px !important;
  }

  @media (max-width: 320px) {
    width: 320px !important;
  }
}

.address-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  padding: 5px 0 10px 0;

  .popover-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;

    color: #FFFFFF;

    &.color-scheme-DARK {
      color: #fff;
    }

    &.color-scheme-LIGHT {
      color: #0C0C23;
    }
  }

  .popover-content {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .popover-field {
      display: flex;
      transition: border-color .25s ease;
      border-bottom: 0.5px solid #5D5F7C !important;

      input {
        height: 24px;
        background: transparent;
        border-radius: 0;
        border: 0 !important;
        width: 170px;
        outline: none;
        text-align: left !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        bottom: 6px;
        color: #999BBC;
      }

      .popover-image-circle {
        width: auto;
        height: 16px;
        margin-right: 4px;
        bottom: 0;
        border-radius: 16px;
        margin-top: 2px;
        object-fit: cover;
        cursor: default;
      }
    }

    .popover-images-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      a {
        display: flex;
      }
    }

    img {
      padding: 0 3px;
      cursor: pointer;
    }
  }
}
.loading {
  display: flex;
  height: 65vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message {
  display: flex;
  height: 65vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      font-family: Montserrat;
      line-height: 15px;
    }
  }
}

.color-scheme {
  &-DARK {
    .message {

      p,
      span {
        color: #fff;
      }
    }
  }
}

.color-scheme {
  &-LIGHT {
    .message {

      p,
      span {
        color: #0A0A1E;
      }
    }
  }
}
