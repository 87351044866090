@import '../../Constants/Styles/colors.scss';
.AuctionsPage__wrapper {
    padding-right: 1.5rem;
    
    @media (max-width: 425px) {
        padding-right: 0.5rem;
    }

    .AuctionTable {

        .auctions-table {
            width: 100%;

            @media (max-width: 1024px) {
                white-space: nowrap;
            }

            thead {
                position: sticky;
                top: 0;

                &.color-scheme {
                    &-DARK {
                        background-color: #0A0A1E;
                    }
                    &-LIGHT {
                        background-color: #F6F6FF;
                    }
                }

                tr {
                    &.row-green {
                        background-color: rgba(27, 162, 89, 0.1);
                        animation: flashing 1.4s linear infinite;
                
                        th {
                            box-shadow: inset 0 -2.5px 1px -2px #1ba259;
                        }
                        
                    }
                }
                

                th {
                    box-shadow: inset 0 -3px 1px -2px #34344d;

                    &.auctions-table-line {
                        box-shadow: inset 0 -3.5px 1px -2px #34344d;
                    }
                    &:first-child {
                        width: 8%;
                    }

                    &:nth-child(2) {
                        width: 11%;
                    }

                    &:nth-child(3) {
                        width: 27%;

                        @media (max-width: 768px) {
                            width: 45%;
                        }
                    }

                    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                        width: 14%;

                        @media (max-width: 768px) {
                            width: 10%;
                        }
                    }
                }
            }

            tbody {

                tr {
                    &.row-green {
                        background-color: rgba(27, 162, 89, 0.1);
                        animation: flashing 1.4s linear infinite;

                        td {
                            border-top: 1.1px solid rgba(27, 162, 89, 0.7);
                            border-bottom: 0.5px solid rgba(27, 162, 89, 0.7);
                        }
                        
                    }

                    &.color-scheme {
                        &-DARK {
                            color: $white0;
                        }
                        &-LIGHT {
                            color: $darkblue1;
                        }
                    }

                    td {
                        white-space: nowrap;
                        font-size: 12px;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        vertical-align: middle;
    
                        &.auctions-item-bold {
                            text-decoration: none;
                            font-weight: 700;
                        }
    
                        &.auctions-item-bid {
                            padding-right: 9%;
                            text-align: right;

                            @media (max-width: 1680px) {
                                padding-right: 8%;

                                @media (max-width: 1440px) {
                                    padding-right: 6%;

                                    @media (max-width: 1024px) {
                                        padding-right: 2%;
                                    }
                                }
                            }
                            
                            .item-btn-green {
                                position: relative;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 65px;
                                    padding: 0 0.3rem;
                                    border-radius: 100px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-weight: 400;
                                    white-space: nowrap;
                                    background: rgba(27, 162, 89, 0.2)!important;

                                    height: 21px;
                                    top: -2px;
                                    right: -17px;
                                }
                                
                              } 
                        }
    
                        &.text-green {
                            color: #1BA159;
                            font-weight: 700;
                        }
    
                        &.text-yellow {
                            color: #D6A72E;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .actions-refresh-section {
        width: 9rem;
        height: 3.5rem;

        .derivatives-tabs-anim-svg {
            right: 0;

            &.color-scheme {
                &-DARK {
                    color: $white0;
                }
                &-LIGHT {
                    color: $darkblue1;
                }
            }
        }
    }

    .auctions-table-height {
        height: calc(95vh - 140px);
        @media screen and (max-width: 425px) {
            height: calc(95vh - 200px);
        }
    }
}

@keyframes flashing {
    0% {
        background-color: rgba(27, 162, 89, 0.1);
    }
    50% {
        background-color: rgba(27, 162, 89, 0.2);
    }
    100% {
        background-color: rgba(27, 162, 89, 0.1);
    }
  }


  table.transparent-table {
    color: #999BBC;
    thead {
          width: 100%;
      th {
              width: 25%;
        padding: 10px 15px;
        font-weight: 700;
        font-size: 12px;
        vertical-align: middle;
      }
    }
  
    tbody {
      border-radius: 10px;
      padding: 0px 10px;
  
      td {
        padding: 15px;
        box-shadow: inset 0 -3px 1px -2px #34344d;
        &.auctions-double-string {
            padding: 10px 15px;
        }
      }
    }
  
      &.clickable-rows {
          tbody {
        tr {
          &:hover {
            background: rgba(93, 95, 124, 0.3);
            cursor: pointer;
          }
        }
      }
      }
  }

.page-title {
    font-family: Montserrat;
	font-size: 26px;
	line-height: 40px;
	font-weight: bold;
	margin-bottom: 1rem;
    &.color-scheme {
        &-DARK {
            color: $white0;
        }
        &-LIGHT {
            color: $darkblue1;
        }
    }

	@media screen and (max-width: 500px) {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 0.5rem;
	}
}

.derivatives-tab-section {
    position: relative;

    .derivatives-tabs-anim-svg {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
        transition: background-color 0.15s linear;
        border: 1px solid #5D5F7C;
        box-sizing: border-box;
        border-radius: 42px;
        width: 114px;
        height: 25px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: white;
        display: flex;
        align-items: center;
        padding-left: 10px;

        &:hover {
            background-color: #999BBC;
        }

        @media (max-width: 425px) {
            top: 0.5rem;
        }

        svg {
            position: absolute;
            right: 8px;
            top: 4px;
        }

        .derivatives-dash {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: dash 15s linear;
          }

          .auction-dash {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: dash 5s linear;
          }

        .derivatives-circle {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: circle-path 2.5s linear infinite;
            transform-origin: 50% 50%;
        }

        .circle-svg {
            animation: 2s linear infinite both circle;

            circle {
                position: absolute;
                right: 8px;
            }
        }
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 47;
    }
    100% {
        stroke-dashoffset: 0;
    }
  }
  
  @keyframes circle {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }

  @keyframes circle-path {
    0%,
    25% {
      stroke-dashoffset: 47;
      transform: rotate(0);
    }
  
    50%,
    75% {
      stroke-dashoffset: 13;
      transform: rotate(45deg);
    }
  
    100% {
      stroke-dashoffset: 47;
      transform: rotate(360deg);
    }
  }

  .approve-popup-content {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .approve-error-popup-content {
    display: flex;
    justify-content: center;
  }

  .auction-sticky-row {
    th {
        font-weight: 400!important;
        padding: 15px!important;

        &.auctions-item-bold {
            text-decoration: none;
            font-weight: 700!important;
        }
    }
    &.color-scheme {
        &-DARK {
            color: $white0;
        }
        &-LIGHT {
            color: $darkblue1;
        }
    }
  }

.auctions-item-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

th.button-in-table-center {
    width: 100%;
    text-align: center;
    padding: 1rem 0 3rem 0!important;
    position: relative;

    .actions-wrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin-left: 2rem;
        }
    
        .info-block {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            top: 20px;
            color: #fff;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Montserrat';
            font-style: normal;
        }
    }
}
.th-history {
    width: 100%;
    text-align: center;
}

.auctionChart {
    position: relative;
    .chart-info {
        position: relative;
        padding-right: 2rem;
        display: block;
        width: 100%;
        text-align: right;

        &-end {
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .chart-loading {
        width: 100%;
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chart-btn {
        // width: 100%;
        // justify-content: center;
        // display: flex;
        margin-left: 2rem;
    }
}

.info-text__color {
    &-DARK {
        color: #fff;
    }

    &-LIGHT {
        color: $darkblue1;
    }
}

th.auctions-item-bid {
    > div {
        white-space: nowrap;
    }
}

.auctions-item-flex {
    display: flex;
    align-items: center;
}

.auctions-table-icon {
    margin-left: 0.4rem;
    margin-right: 0.4rem;

    & img {
        height: 0.8rem;
        width: 0.8rem;
    }
}

.fill-input {
    min-width: 15rem;
    // margin-left: 0.3rem;
    // margin-right: 0.6rem;
}

.auctions-item-fill {
    min-width: 100px;
}

.auctions-fill-button {
    width: 30%;
}
