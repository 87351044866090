.CreateAuction__wrapper {
    display: flex;
    justify-content: center;
    padding-right: 1.5rem;
    height: 80vh;

    @media (max-width: 425px) {
        padding-right: 0.5rem;
    }

    .CreateAuction_form {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px 0px;

        @media (max-width: 425px) {
            width: 100%;
        }

        .Token_image {
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }

        .dropdown {
            width: 100%;

            button {
                width: 100%;
            }
        }

        button {
            width: 100%;
            border-radius: 4px;
            text-transform: capitalize;
        }

        .RoundedBorder_Card {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px 0px;

            border: 1px solid white;
            border-radius: 4px;
            padding: 10px 10px;

            @media (max-width: 425px) {
                flex-direction: column;
            }

            div {
                width: 50%;

                @media (max-width: 425px) {
                    width: 100%;
                }
            }

            &.Full_Width {
                div {
                    width: 100%;
                }
            }

            button {
                width: initial;
                width: 50%;
            }

            input[type="date"] {
                width: 50%;
            }

            .Chart_wrapper {
                width: 100%;
            }
        }

        .CurrencyInput {
            display: flex;
            flex-direction: row;
            gap: 0px 10px;
            height: 32px;

            span {
                height: 100%;

                input {
                    height: 100%;
                }
            }
        }
    }
}
